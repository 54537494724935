import api from '../../../api'

export const FETCH_POSICOES_PENDING = 'FETCH_POSICOES_PENDING'
export const FETCH_POSICOES_FULFILLED = 'FETCH_POSICOES_FULFILLED'
export const FETCH_POSICOES_REJECTED = 'FETCH_POSICOES_REJECTED'

export const FETCH_TELEMETRY_POSITIONS_PENDING = 'FETCH_TELEMETRY_POSITIONS_PENDING'
export const FETCH_TELEMETRY_POSITIONS_FULFILLED = 'FETCH_TELEMETRY_POSITIONS_FULFILLED'
export const FETCH_TELEMETRY_POSITIONS_REJECTED = 'FETCH_TELEMETRY_POSITIONS_REJECTED'

export const FETCH_GRID_TEMPERATURE_3_ZONES_PENDING = 'FETCH_GRID_TEMPERATURE_3_ZONES_PENDING'
export const FETCH_GRID_TEMPERATURE_3_ZONES_FULFILLED = 'FETCH_GRID_TEMPERATURE_3_ZONES_FULFILLED'
export const FETCH_GRID_TEMPERATURE_3_ZONES_REJECTED = 'FETCH_GRID_TEMPERATURE_3_ZONES_REJECTED'

export const FETCH_GRID_CONTAINER_PENDING = 'FETCH_GRID_CONTAINER_PENDING'
export const FETCH_GRID_CONTAINER_FULFILLED = 'FETCH_GRID_CONTAINER_FULFILLED'
export const FETCH_GRID_CONTAINER_REJECTED = 'FETCH_GRID_CONTAINER_REJECTED'

export const FETCH_GRID_TEMPERATURE_UMIDITY_PENDING = 'FETCH_GRID_TEMPERATURE_UMIDITY_PENDING'
export const FETCH_GRID_TEMPERATURE_UMIDITY_FULFILLED = 'FETCH_GRID_TEMPERATURE_UMIDITY_FULFILLED'
export const FETCH_GRID_TEMPERATURE_UMIDITY_REJECTED = 'FETCH_GRID_TEMPERATURE_UMIDITY_REJECTED'

export function findAllPositions(filterOptions) {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({ type: FETCH_POSICOES_PENDING });

        api({ 
            method: 'post',     
            data: {
                query: `
                        query findAllPositions {
                          posicoes (first: 1000 offset: 0 
                            filterOptions: { 
                              matriz: ${filterOptions.matriz}
                              placa: "${filterOptions.placa}"
                              numeroFrota: ${filterOptions.numeroFrota}
                              descricao: "${filterOptions.descricao}"
                              equipamento: "${filterOptions.equipamento}"
                              modelo: ${filterOptions.modelo}
                              statusGPRS: ${filterOptions.statusGPRS} 
                            }) {
                            veiculo {
                              codigo
                              matriz
                              tipoVeiculo
                              manutencao 
                              veiculoTipo {
                                ligadaIcon
                                desligadaIcon
                                panicoIcon
                                terminalIcon
                                misturaIcon
                                descargaIcon
                              } 
                              perfilPortas
                              garagem
                              grupo
                              placa
                              numeroFrota
                              descricao
                              prefixo
                              marca
                              modelo
                              numeroFrota
                              configuracoes
                              veiculoGaragem {
                                descricao
                              }
                              veiculoGrupo {
                                descricao
                              }
                              clienteMatriz {
                                razaoSocial
                              }
                            }
                            motorista {
                              codigo
                              nome
                            }
                            modulo
                            dataHoraServidor
                            dataHoraGps
                            ignicao
                            panico
                            gps
                            gprs
                            entrada1
                            entrada2
                            entrada3
                            entrada4
                            saida1
                            saida2
                            saida3
                            saida4
                            temperatura
                            latitude
                            longitude
                            velocidade
                            rpm
                            hodometro
                            horimetro
                            direcao
                            ponto
                            pontoConhecido {
                              descricao
                            }
                            area
                            rota
                            endereco
                            tensaoInterna
                            tensaoExterna
                          }
                        }
                        `
            }
        }).then((result) => {
            const { errors, data } = result.data
            if (errors) {
                dispatch({ 
                  type: FETCH_POSICOES_REJECTED, 
                  payload: errors });
                resolve(errors);
            } else {
                const { posicoes } = data
                dispatch({ 
                  type: FETCH_POSICOES_FULFILLED, 
                  payload: posicoes });
                resolve(data);
            }
        }).catch((error) => {
            dispatch({ 
              type: FETCH_POSICOES_REJECTED, 
              payload: error });
            resolve(error);
        });

    });

}

export function findAllPositionsByVehicles(vehicles) {

  return dispatch => new Promise((resolve, reject) => {

      api({ 
          method: 'post',     
          data: {
              query: `
                      query findAllPositionsByVehicles {
                        posicoesPorVeiculos (veiculos: "${vehicles}") {
                          veiculo {
                            codigo
                            matriz
                            tipoVeiculo
                            perfilPortas
                            garagem
                            grupo
                            placa
                            numeroFrota
                            descricao
                            prefixo
                            marca
                            modelo
                            numeroFrota
                            configuracoes
                            manutencao
                            veiculoTipo {
                              ligadaIcon
                              desligadaIcon
                              panicoIcon
                              terminalIcon
                              misturaIcon
                              descargaIcon
                            }
                            clienteMatriz {
                              razaoSocial
                            }
                          }
                          motorista {
                            codigo
                            nome
                          }
                          dataHoraGps
                          ignicao
                          panico
                          gps
                          gprs
                          ponto
                          pontoConhecido {
                            descricao
                          }
                          entrada1
                          entrada2
                          entrada3
                          entrada4
                          saida1
                          saida2
                          saida3
                          saida4
                          temperatura
                          latitude
                          longitude
                          velocidade
                          endereco
                        }
                      }
                      `
          }
      }).then((result) => {
          const { errors, data } = result.data
          if (errors) {
              resolve(errors);
          } else {
              const { posicoesPorVeiculos } = data
              resolve(posicoesPorVeiculos);
          }
      }).catch((error) => {
          resolve(error);
      });

  });

}

export function findAllTelemetryPositionsByVehicles(vehicles) {

  return dispatch => new Promise((resolve, reject) => {

      api({ 
          method: 'post',     
          data: {
              query: `
                      query findAllTelemetryPositionsByVehicles {
                        posicoesTelemetriaPorVeiculos (veiculos: "${vehicles}") {
                          veiculo {
                            codigo
                            matriz
                            tipoVeiculo
                            placa
                            numeroFrota
                            configuracoes
                            veiculoTipo {
                              ligadaIcon
                              desligadaIcon
                              panicoIcon
                              terminalIcon
                              misturaIcon
                              descargaIcon
                            }
                            clienteMatriz {
                              razaoSocial
                            }
                            capacidadeTanque
                            tensaoVazio
                            tensao1Quarto
                            tensaoMeio
                            tensao3Quartos
                            tensaoCheio
                            leituraInversa
                            manutencao
                          }
                          motorista {
                            codigo
                            nome
                          }
                          posicaoGps {
                            endereco
                          }
                          modulo
                          dataHoraServidor
                          dataHoraGps
                          ignicao
                          isOnline
                          entradaOito
                          saidaUm
                          saidaDois
                          saidaTres
                          saidaQuatro
                          temperatura
                          latitude
                          longitude
                          velocidade
                          rpm
                          odometro
                          valorAnalogico
                        }
                      }
                      `
          }
      }).then((result) => {
          const { errors, data } = result.data
          if (errors) {
              resolve(errors);
          } else {
              const { posicoesTelemetriaPorVeiculos } = data
              resolve(posicoesTelemetriaPorVeiculos);
          }
      }).catch((error) => {
          resolve(error);
      });

  });

}

export function findAllTelemetryPositions(filterOptions) {

  return dispatch => new Promise((resolve, reject) => {

      dispatch({ type: FETCH_TELEMETRY_POSITIONS_PENDING });

      api({ 
          method: 'post',     
          data: {
              query: `
                      query findAllTelemetryPositions {
                        posicoesTelemetria (first: 1000 offset: 0 
                          filterOptions: { 
                            matriz: ${filterOptions.matriz}
                            placa: "${filterOptions.placa}"
                            numeroFrota: ${filterOptions.numeroFrota}
                            descricao: "${filterOptions.descricao}"
                            equipamento: "${filterOptions.equipamento}"
                            modelo: ${filterOptions.modelo}
                            statusGPRS: ${filterOptions.statusGPRS} 
                          }) {
                          veiculo {
                            codigo
                            matriz 
                            tipoVeiculo
                            perfilPortas
                            garagem
                            grupo
                            placa
                            numeroFrota
                            descricao
                            prefixo
                            marca
                            modelo
                            numeroFrota
                            configuracoes 
                            manutencao
                            veiculoTipo {
                              ligadaIcon
                              desligadaIcon
                              panicoIcon
                              terminalIcon
                              misturaIcon
                              descargaIcon
                            }
                            veiculoGaragem {
                              descricao
                            }
                            veiculoGrupo {
                              descricao
                            }
                            clienteMatriz {
                              razaoSocial
                            }
                          }
                          posicaoGps {
                            endereco
                          }
                          motorista {
                            codigo
                            nome
                          }
                          modulo
                          dataHoraServidor
                          dataHoraGps
                          ignicao
                          entradaUm
                          entradaDois
                          entradaTres
                          entradaQuatro
                          entradaCinco
                          entradaSeis
                          entradaSete
                          entradaOito
                          saidaUm
                          saidaDois
                          saidaTres
                          saidaQuatro
                          temperatura
                          latitude
                          longitude
                          velocidade
                          rpm
                          serialRfid
                          odometro
                        }
                      }
                      `
          }
      }).then((result) => {
          const { errors, data } = result.data
          if (errors) {
              dispatch({ 
                type: FETCH_TELEMETRY_POSITIONS_REJECTED, payload: errors });
              resolve(errors);
          } else {
              const { posicoesTelemetria } = data
              dispatch({ type: FETCH_TELEMETRY_POSITIONS_FULFILLED, payload: posicoesTelemetria });
              resolve(posicoesTelemetria);
          }
      }).catch((error) => {
          dispatch({ type: FETCH_TELEMETRY_POSITIONS_REJECTED, payload: error });
          resolve(error);
      });

  });

}

export function findAllTemperature3Zones(filter, first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_GRID_TEMPERATURE_3_ZONES_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllTemperature3Zones {
                  posicoesTemperatura3Zonas (first: ${first} offset: ${offset} 
                    filterOptions: { 
                      matriz: ${filter.matriz}
                      placa: "${filter.placa}"
                      numeroFrota: ${filter.numeroFrota}
                      descricao: "${filter.descricao}"
                      equipamento: "${filter.equipamento}"
                      modelo: ${filter.modelo}
                      statusGPRS: ${filter.statusGPRS} 
                    }
                  ) {
                    modulo
                    dataHoraServidor 
                    dataHoraGps
                    latitude
                    longitude
                    temperaturaUm
                    temperaturaDois
                    temperaturaTres
                    statusTemperaturaUm
                    statusTemperaturaDois
                    statusTemperaturaTres
                    veiculo {
                      codigo
                      placa
                      numeroFrota
                      descricao
                      clienteMatriz {
                        razaoSocial
                      }
                    }
                    posicaoGps {
                      ignicao
                      panico
                      gps
                      gprs
                      entrada1
                      entrada2
                      entrada3
                      entrada4
                      saida1
                      saida2
                      saida3
                      saida4
                      velocidade
                      endereco
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_GRID_TEMPERATURE_3_ZONES_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { posicoesTemperatura3Zonas } = data
        dispatch({ type: FETCH_GRID_TEMPERATURE_3_ZONES_FULFILLED, payload: posicoesTemperatura3Zonas });
        resolve(posicoesTemperatura3Zonas);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_GRID_TEMPERATURE_3_ZONES_REJECTED, error });
      reject(error);
    });

  });

}

export function findAllTemperatureHumidity(filter, first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_GRID_TEMPERATURE_UMIDITY_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllTemperatureHumidity {
                  posicoesTemperaturaUmidade (first: ${first} offset: ${offset} 
                    filterOptions: { 
                      matriz: ${filter.matriz}
                      placa: "${filter.placa}"
                      numeroFrota: ${filter.numeroFrota}
                      descricao: "${filter.descricao}"
                      equipamento: "${filter.equipamento}"
                      modelo: ${filter.modelo}
                      statusGPRS: ${filter.statusGPRS} 
                    }
                  ) {
                    modulo
                    dataHoraServidor
                    dataHoraGps
                    latitude
                    longitude
                    tipoEquipamento
                    temperatura
                    temperaturaDois
                    umidade
                    statusTemperaturaUm
                    statusTemperaturaDois
                    statusTemperaturaTres
                    veiculo {
                      codigo
                      placa
                      numeroFrota
                      descricao
                      clienteMatriz {
                        razaoSocial
                      }
                      veiculoGaragem {
                        descricao
                      }
                    }
                    posicaoGps {
                      ignicao
                      panico
                      gps
                      gprs
                      entrada1
                      entrada2
                      entrada3
                      entrada4
                      saida1
                      saida2
                      saida3
                      saida4
                      velocidade
                      endereco
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_GRID_TEMPERATURE_UMIDITY_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { posicoesTemperaturaUmidade } = data
        dispatch({ type: FETCH_GRID_TEMPERATURE_UMIDITY_FULFILLED, payload: posicoesTemperaturaUmidade });
        resolve(posicoesTemperaturaUmidade);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_GRID_TEMPERATURE_UMIDITY_REJECTED, error });
      reject(error);
    });

  });

}

export function findAllContainer(filter, first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_GRID_CONTAINER_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllPosicoesContainer {
                  posicoesContainer (first: ${first} offset: ${offset} 
                    filterOptions: { 
                      matriz: ${filter.matriz}
                      placa: "${filter.placa}"
                      numeroFrota: ${filter.numeroFrota}
                      descricao: "${filter.descricao}"
                      equipamento: "${filter.equipamento}"
                      modelo: ${filter.modelo}
                      statusGPRS: ${filter.statusGPRS} 
                    }
                  ) {
                    modulo
                    dataHoraServidor
                    dataHoraGps
                    latitude
                    longitude
                    tipoEquipamento
                    serial
                    valorSetpoint
                    valorSupply
                    valorReturn
                    valorHorimetro
                    valorAnalogico
                    valorCombustivel
                    entradaUm
                    entradaDois
                    entradaTres
                    entradaQuatro
                    saidaUm
                    saidaDois
                    saidaTres
                    saidaQuatro
                    tensaoInterna
                    tensaoExterna
                    veiculo {
                      placa
                      numeroFrota
                      descricao
                      clienteMatriz {
                        razaoSocial
                      }
                      veiculoGaragem {
                        descricao
                      }
                    }
                    posicaoGps {
                      ignicao
                      panico
                      gps
                      gprs
                      entrada1
                      entrada2
                      entrada3
                      entrada4
                      saida1
                      saida2
                      saida3
                      saida4
                      velocidade
                      endereco
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_GRID_CONTAINER_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { posicoesContainer } = data
        dispatch({ type: FETCH_GRID_CONTAINER_FULFILLED, payload: posicoesContainer });
        resolve(posicoesContainer);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_GRID_CONTAINER_REJECTED, error });
      reject(error);
    });

  });

}

export function findAllThermoking(filter, first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_GRID_CONTAINER_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllPosicoesContainer {
                  posicoesContainer (first: ${first} offset: ${offset} 
                    filterOptions: { 
                      matriz: ${filter.matriz}
                      placa: "${filter.placa}"
                      numeroFrota: ${filter.numeroFrota}
                      descricao: "${filter.descricao}"
                      equipamento: "${filter.equipamento}"
                      modelo: ${filter.modelo}
                      statusGPRS: ${filter.statusGPRS} 
                    }
                  ) {
                    modulo
                    dataHoraServidor
                    dataHoraGps
                    latitude
                    longitude
                    tipoEquipamento
                    serial
                    valorSetpoint
                    valorSupply
                    valorReturn
                    valorHorimetro
                    valorAnalogico
                    valorCombustivel
                    entradaUm
                    entradaDois
                    entradaTres
                    entradaQuatro
                    saidaUm
                    saidaDois
                    saidaTres
                    saidaQuatro
                    tensaoInterna
                    tensaoExterna
                    veiculo {
                      placa
                      numeroFrota
                      descricao
                      clienteMatriz {
                        razaoSocial
                      }
                      veiculoGaragem {
                        descricao
                      }
                    }
                    posicaoGps {
                      ignicao
                      panico
                      gps
                      gprs
                      entrada1
                      entrada2
                      entrada3
                      entrada4
                      saida1
                      saida2
                      saida3
                      saida4
                      velocidade
                      endereco
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_GRID_CONTAINER_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { posicoesContainer } = data
        dispatch({ type: FETCH_GRID_CONTAINER_FULFILLED, payload: posicoesContainer });
        resolve(posicoesContainer);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_GRID_CONTAINER_REJECTED, error });
      reject(error);
    });

  });

}

export function findAllGensetPositionsByVehicles(vehicles) {

  return dispatch => new Promise((resolve, reject) => {

      api({ 
          method: 'post',     
          data: {
              query: `
                      query findAllGensetPositionsByVehicles {
                        posicoesPorContainers (veiculos: "${vehicles}") {
                          modulo
                          dataHoraServidor
                          dataHoraGps
                          latitude
                          longitude
                          tipoEquipamento
                          serial
                          valorHorimetro
                          valorAnalogico
                          valorCombustivel
                          entradaUm
                          entradaDois
                          entradaTres
                          entradaQuatro
                          saidaUm
                          saidaDois
                          saidaTres
                          saidaQuatro
                          serialDois
                          entradaCinco
                          entradaSeis
                          entradaSete
                          entradaOito
                          valorHorimetroDois
                          valorHorimetroTres
                          tensaoDisplay
                          tensaoBateria
                          temperaturaAgua
                          frequenciaRede
                          tensaoRede
                          correnteGerador
                          correnteUso
                          rpmMotor
                          statusErro
                          statusSpeed
                          ecuPower
                          ecuStatus
                          veiculo {
                            codigo
                            placa
                            numeroFrota
                            descricao
                            veiculoTipo {
                              ligadaIcon
                              desligadaIcon
                              panicoIcon
                              terminalIcon
                              misturaIcon
                              descargaIcon
                            }
                            clienteMatriz {
                              razaoSocial
                            }
                            veiculoGaragem {
                              descricao
                            }
                          }
                        }
                      }
                      `
          }
      }).then((result) => {
          const { errors, data } = result.data
          if (errors) {
              resolve(errors);
          } else {
              const { posicoesPorContainers } = data
              resolve(posicoesPorContainers);
          }
      }).catch((error) => {
          resolve(error);
      });

  });

}